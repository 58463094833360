<template>
    <div class="m-2">
       <div class="content-header my-3">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6" style="padding-left: 26px !important;">
                    <h1 class="m-0 text-dark capitalize">
                       Transaksi
                    </h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <!-- <ol class="breadcrumb float-sm-right ">
                        <li>
                            <button type="button" class="btn btn-block btn-outline-primary btn-lg">Export</button>
                        </li>
                        <li class="ml-3"><button type="button" class="btn btn-block btn-primary btn-lg"  @click="showModal"><span class="fas fa-plus"> </span>  Add Merchant</button></li>
                    </ol> -->
                </div><!-- /.col -->
            </div><!-- /.row -->
             <div class="alert alert-danger alert-dismissible" v-if="error">
                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                <h5>
                    <i class="icon fa fa-ban"></i>
                    Gagal!
                </h5>
                <p>{{ error }}</p>
            </div>
            <div v-if="dataTransaksi?.length > 0" style="text-align: right;">
                <div class="form-group">
                    <button type="button" class="btn btn-primary btn-lg" @click="btnDownload" :disabled="date?.[0] === null || date === null" style="margin-right:16px !important; width: 176px;height:40px; font-weight: 700;">Unduh Laporan</button>
                </div> 

                <span v-if="date?.[0] === null || date === null" style="display: block;color: #454545; margin-right: 10px; margin-top:-20px;"> <i class="fas fa-info-circle mr-1 mt-2"></i>Tentukan periode tanggal terlebih dahulu.</span>
            </div><!-- /.col -->
        </div><!-- /.container-fluid -->
    </div>      
        <div class="table-wrapper" style="position: relative;">
            <div v-if="dataTransaksi?.length > 0" style="position: absolute; top: 13px; left: 35px;width: 76%;z-index: 1;">
                <div class="row mb-2">
                    <div class="col-sm-1" style="margin-top: 7px;">
                        <span style="font-size: 16px; text-align: right;">Filter :</span>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <!-- <label for="recipient-status" class="col-form-label">Status</label> -->
                            <select class="form-control select-status" v-model="status" @change="filterTransaction">
                                <option disabled>Pilih Status Transaksi</option>
                                <option value="">Semua Status</option>
                                <option value="Success">Selesai</option>
                                <option value="Decline">Gagal</option>
                                <!-- <option value="Pending">Tertunda</option> -->
                            </select>
                        </div>
                    </div><!-- /.col -->
                    <div class="col-sm-4">
                        <div class="form-group">
                            <!-- <label for="recipient-name" class="col-form-label">Date Mulai</label> -->
                            <date-picker 
                                id="date" 
                                name="date" 
                                v-model="date" 
                                range
                                @change="filterTransaction"
                                placeholder="Pilih Periode Tanggal"
                                format="DD-MM-YYYY"
                            />
                        </div>
                    </div><!-- /.col -->
                   
                </div>
            </div>
            <div v-if="dataTransaksi?.length > 0">
                <data-tables-master-data :config="config" class="container-fluid" id="sampleTable"></data-tables-master-data>  
            </div>
            <div v-else class="image-empty"> 
                <img src="../../assets/img/emptyTransaction.png" alt="empty-transaction">
            </div>

        </div>
    </div>
</template>


<script>
const {console} = window
import DataTablesMasterData from '@components/DataTablesMasterData.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
     components: {
        DataTablesMasterData,
        DatePicker
    },

    data() {
        return {
            date: null,
            status:"",
            error: undefined,
            cari: "",
            dataTransaksi: null,
            config: {
                columns: [{
                    title: 'Tgl Transaksi',
                    data: 'created_at',
                    searchable: false,
                    render: (val) => {
                        return this.formatDate(val)
                    } 
                },{
                    title: 'ID Transaksi',
                    data: 'transaction_code'
                },{
                    title: 'ID Vending',
                    data: 'vending_code'
                },
                {
                    title: 'Status',
                    data: 'status',
                    render: (val) => {
                        var statusPending = `<div class="col-sm-4">
                            <span class="badge-pending">Tertunda</span>
                            </div>`

                        var statusGagal = `<div class="col-sm-4">
                            <span class="badge-gagal">Gagal</span>
                            </div>`

                        var statusSelesai = `<div class="col-sm-4">
                            <span class="badge-selesai">Selesai</span>
                            </div>`

                        return val === "Pending" ? statusPending : val === "Decline" ? statusGagal : statusSelesai
                        
                    }    
                },{
                    title: 'Jenis Barang',
                    data: 'detail',
                    searchable: false,
                    orderable: false,
                    render: (val) => {
                        var productName = ""
                        val.forEach((element) => {
                            productName = productName + `<p> ${element.product.name} </p>` 
                        })
                        return productName
                    }   
                },
                {
                    title: 'Jumlah',
                    data: 'total_amount',
                    searchable: false
                },
                {
                    title: 'Part Number',
                    data: 'detail',
                    searchable: false,
                    orderable: false,
                    render: (val) => {
                        var productName = ""
                        val.forEach((element) => {
                            productName = productName + `<p> ${element.product_sku} </p>` 
                        })
                        return productName
                    }  
                },{
                    title: 'Karyawan',
                    data: 'requester_name',
                },{
                    title: 'MO/WBS',
                    data: 'mo_wbs'
                    
                },{
                    title: 'SAP ID',
                    data: 'detail',
                    searchable: false,
                    orderable: false,
                    render: (val) => {
                        var productName = ""
                        val.forEach((element) => {
                            productName = productName + `<p> ${element.product_sap_id} </p>` 
                        })
                        return productName
                    }  
                },
                {
                    title: '',
                    visible: false,
                    searchable: false
                }
            ],
                url:this.Api.base + '/transaction/',
                order: [[0, "desc"]],
                filter: true,
                module: "transaksi",
                searchPlaceholder: "Cari Transaksi"
                // advanceFilter: advanceFilter
            },
        }
    },
    mounted() {
        let today = moment().format('DD MMM YYYY');
        $('#date').val(today);
        this.getDataTransaksi()
    },
    props: {
      value: Object,
    },
    methods: {
        formatDate(value) {
            return moment(String(value)).format('DD-MM-YY HH:mm')
        },
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        async getDataTransaksi() {
            const res = await Api.get("/transaction")
            this.dataTransaksi = res.data.content
        },
        btnDownload(){
            const convertDate = moment(this.date[0]).format("YYYY-MM-DD")
            const convertDate2 = moment(this.date[1]).format("YYYY-MM-DD")
            console.log(this.date)
             if(this.date[0] != "" & this.date[1] !=""){
                let linkurl = '/report/download/sale/'+convertDate+'/'+convertDate2+''
                let saveAs = 'laporan_transaksi.xlsx'
                let filters= this.status !== "" ? '?filters=[["status","=","'+this.status+'"]]' : '?filters='
                linkurl = linkurl+filters
                Api.download(linkurl, saveAs).then(() => {
                    this.$toast.success("Laporan berhasil diunduh.", {
                        position: "bottom-right",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });
                    this.error=null;
                }).catch((err) => {
                    this.error = "Data tidak tersedia dalam laporan"
                    this.$toast.error("Data tidak tersedia dalam laporan.", {
                        position: "bottom-right",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });
                })
             }else{
                this.error = "Data Tanggal Mulai dan Tanggal Selesai Harus Diisi"
             }
        },
        filterTransaction() {
            const convertDate = moment(this.date ? this.date[0] : null).format("YYYY-MM-DD")
            const convertDate2 = moment(this.date ? this.date[1] : null).add(1, 'days').format("YYYY-MM-DD")
            
            const filter =  this.status 
                ? '[["status","=","'+this.status+'"]]' 
                : this.status && this.date !== null
                ? '[["status","=","'+this.status+'"],["AND"],["created_at","between","'+convertDate+','+convertDate2+'"]]'
                // : this.status === "" 
                // ? '[["status","=",""]]'
                : '[["created_at","between","'+convertDate+','+convertDate2+'"]]'
            
                
            this.config = {
                columns: this.config.columns,
                url: this.Api.base + '/transaction?filters='+filter,
                order: [[0, "desc"]]
            }

            // console.log(table.columns[4], "ss")
            // var stts = this.status ? this.status : ""
            // console.log(table.columns[4].search(stts).draw())
        }
    },
}
</script>

<style lang="sass">
    .badge-pending
        border: 1px solid #CD7B2E
        color: #fff
        background-color: #CD7B2E
        padding: 3px 10px
        border-radius: 5px
        display: inline-block
        padding: 0.25em 0.4em
        font-size: 75%
        font-weight: 700
        line-height: 1
        text-align: center
        white-space: nowrap
        vertical-align: baseline

    .badge-selesai
        border: 1px solid #43936C
        color: #fff
        background-color: #43936C
        padding: 3px 10px
        border-radius: 5px
        display: inline-block
        padding: 0.25em 0.4em
        font-size: 75%
        font-weight: 700
        line-height: 1
        text-align: center
        white-space: nowrap
        vertical-align: baseline

    .badge-gagal
        border: 1px solid #CB3A31
        color: #fff
        background-color: #CB3A31
        padding: 3px 10px
        border-radius: 5px
        display: inline-block
        padding: 0.25em 0.4em
        font-size: 75%
        font-weight: 700
        line-height: 1
        text-align: center
        white-space: nowrap
        vertical-align: baseline
</style>